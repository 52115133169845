"use strict";

var newImgLoader,
  imageLoader,
  thumbLoader,
  introLoader,
  slideList = [],
  animatedThumbList = [],
  mode,
  sort,
  sortlink,
  available,
  availablelink,
  aspectratio_window,
  stopHomeSlides = false,
  title = 'goodnightnur.se',
  isFading = false,
  homeSlideCounter = 0,
  gnn;
var AJAX_URL = phpvars.siteurl + '/wp-content/themes/art_grundig/javascript/ajax.php';
var doingAjax = false,
  swappingContent = false;
var svgs = ['nav', 'close', 'radio_button', 'logo', 'menu_close', 'hamburger'];
var preload = [];
svgs.forEach(function (v, i) {
  preload[i] = new Image();
  preload[i].src = phpvars.home + '/images/' + v + '.svg';
});
(function ($) {
  jQuery.easing.jswing = jQuery.easing.swing;
  jQuery.extend(jQuery.easing, {
    def: 'easeOutQuad',
    swing: function swing(x, t, b, c, d) {
      //alert(jQuery.easing.default)
      return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
    },
    easeOutQuad: function easeOutQuad(x, t, b, c, d) {
      return -c * (t /= d) * (t - 2) + b;
    }
  });
  jQuery.fn.extend({
    gnn: function gnn(options) {
      this.animate = function (el) {
        this.slides = el;
        this.slidecount = 1;
        this.faderate = 1500;
        this.pause = Math.floor(Math.random() * 1500 + 4000);
        if ($(this.slides[0]).parent('.imgholder').length) {
          this.$slide = $($(this.slides[0]).parents('.slide')[0]);
          this.id = this.$slide.attr('id');
          this.$counter = this.$slide.find('.captionholder .counter').children();
        }
        this.start = function () {
          this.timer = setTimeout($.proxy(function () {
            this.startSlides();
          }, this), this.pause + 2000); //linger on 1st slide
        };

        this.startSlides = $.proxy(function () {
          if (this.slidecount >= this.slides.length) {
            this.slidecount = 1;
            setTimeout($.proxy(function () {
              if (this.$slide) {
                this.$slide.removeClass("uv gitd");
              }
              $(this.slides[this.slides.length - 1]).animate({
                opacity: 0
              }, this.faderate - 300, 'easeOutQuad');
            }, this), 300); //delay fadeout
            $(this.slides[0]).animate({
              opacity: 1
            }, this.faderate, 'linear');
            this.timer = setTimeout($.proxy(function () {
              this.startSlides();
            }, this), this.faderate + this.pause + 2000); //linger on 1st slide
            if (this.$counter) {
              $(this.$counter).removeClass('here');
              $(this.$counter[this.slidecount - 1]).addClass('here');
            }
          } else {
            var slide = $(this.slides[this.slidecount]);
            var prevslide = $(this.slides[this.slidecount - 1]);
            if (this.id == mode && this.$slide) {
              var type = slide.attr('data-type');
              this.$slide.removeClass("uv gitd").addClass(type);
            }
            if (this.$counter) {
              $(this.$counter).removeClass('here');
              $(this.$counter[this.slidecount]).addClass('here');
            }
            slide.animate({
              opacity: 1
            }, this.faderate, 'linear', $.proxy(function () {
              this.slidecount++;
              this.timer = setTimeout(this.startSlides, this.pause);
            }, this));
            setTimeout($.proxy(function () {
              prevslide.animate({
                opacity: 0
              }, this.faderate - 300, 'easeOutQuad');
            }, this), 300); //delay fadeout
          }
        }, this);
        this.restart = function () {
          this.stopAnimation();
          this.start();
        };
        this.stopAnimation = function () {
          clearTimeout(this.timer);
          $('.slide').removeClass("uv gitd");
          this.slidecount = 1;
          $(this.slides.slice(1)).stop(true).css({
            opacity: 0
          });
          $(this.slides[0]).stop(true).css({
            opacity: 1
          });
          if (this.$counter) {
            $(this.$counter).removeClass('here');
            $(this.$counter[0]).addClass('here');
          }
        };
      };
      return {
        animate: this.animate
      };
    }
  });
})(jQuery);
jQuery(document).ready(function ($) {
  gnn = $(document).gnn();
  $.ajaxSetup({
    url: AJAX_URL,
    type: "POST"
  });
  $(window).on("resize", function () {
    //clean up mobile menu
    if ($('#header').hasClass('open') && $(window).width() >= 768) {
      $('#hamburger').trigger('click');
      $('#header').removeClass('open');
    }

    //hell if I know, scaling the home slides I guess
    if ($('#slide_home_holder').is(':visible')) {
      aspectratio_window = $(window).width() / $(window).height();
      $('.slide_home').each(function () {
        scaleSlideshow($(this));
      });
    }

    //scale the thumb placeholders before they're loaded
    $('.thumb img').each(function (i, e) {
      var ar = $(e).attr('data-aspectratio');
      //if image is loaded already, this is moot
      //FIXME: this doesn't work a lot of the time
      if (ar) {
        var $a = $(e).parent();
        var ww = $(window).width();
        var imgw = $a.innerWidth() / ww * 100; // expressed as vw
        $a.css({
          height: imgw * ar + 'vw'
        });
      }
    });
  });
  $(window).trigger('resize');
  var imageLoader = new jb_imgLoad();
  imageLoader.addImages('slideshow', '.slide_home img', {
    onCompleteImage: function onCompleteImage(el) {
      homeSlideCounter++;
      if (homeSlideCounter == $('.slide_home').length - 1) {
        $('#logo').fadeIn(500);
        $('#slide_home_holder').fadeIn(500);
      }
      if (homeSlideCounter == $('.slide_home').length) {
        var firstSlide = $('.slide_home:eq(' + ($('.slide_home').length - 1) + ')');
        firstSlide.data('percent_complete', 0);
        scaleSlideshow(firstSlide);
        firstSlide.show().css('opacity', 1);
        $('#homeloading').fadeOut(500, function () {
          $('body').removeClass('loading');
          animateHomeSlide(firstSlide);
        });
      }
    },
    onCompleteQ: function onCompleteQ(qName) {
      $(window).trigger('resize');
      imageLoader.seek('thumbs');
    }
  });
  imageLoader.addImages('thumbs', '#thumbs img', {
    onStartImage: function onStartImage(el) {
      el.parent().addClass('loading');
    },
    onCompleteImage: function onCompleteImage(el) {
      //remove sizing of placeholder
      el.removeAttr('data-aspectratio');
      el.parent().css({
        height: ''
      }).removeClass('loading');
      //check for multiple images in group
      if (el.siblings('img').length && !el.siblings('img[data-aspectratio]').length) {
        animatedThumbList.push(new gnn.animate(el.parent().find('img')));
      }
    },
    onCompleteQ: function onCompleteQ(qName) {
      imageLoader.seek('portfolio');
      if (mode == 'thumbs') {
        for (var _i = 0, _animatedThumbList = animatedThumbList; _i < _animatedThumbList.length; _i++) {
          var thumb = _animatedThumbList[_i];
          thumb.restart();
        }
      }
    }
  });
  imageLoader.addImages('portfolio', '.imgholder img', {
    onCompleteImage: function onCompleteImage(el) {
      el.parent('figure.extra').addClass('loaded');
      //check for multiple images in group
      var imgholder = el.closest('.imgholder');
      var id = imgholder.parent().attr('id');
      if (imgholder.find('figure.extra').length) {
        if (imgholder.find('figure.extra').length == imgholder.find('figure.extra.loaded').length) {
          var i = slideList.findIndex(function (x) {
            return x.id === id;
          });
          if (slideList[i].animation === null) {
            slideList[i].animation = new gnn.animate(imgholder.find('figure'));
          }
          imgholder.siblings('.loading').fadeOut(300, function () {
            $(this).remove();
          });
        }
      } else {
        imgholder.siblings('.loading').fadeOut(300, function () {
          $(this).remove();
        });
      }
    }
  });

  //touchscreens can't deal with vh CSS
  if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    var wh = $(window).height();
    $('<style>').text('#slides .slide .imgholder img { max-height: ' + (wh - 30) + 'px; }').appendTo('head');
  }

  //calculate heights of elements so we can use max-height trick for accordion
  var initAccordion = function initAccordion(el) {
    el.each(function (e) {
      var h;
      var $this = $(el[e]);
      var $clone = $this.clone().appendTo('body');
      $clone.css({
        'overflow': 'visible',
        'max-height': 'none',
        'z-index': -1
      });
      $this.show();
      h = $clone.outerHeight();
      $clone.remove();
      var extraPadding = 160; //fudge factor, because of padding and borders
      $this.css({
        'overflow': 'hidden',
        'max-height': 0
      });
      $this.attr('data-maxH', h + extraPadding);
    });
  };
  var initSlideList = function initSlideList() {
    slideList = [];
    $('.slide').each(function () {
      slideList.push({
        id: $(this).attr('id'),
        animation: null
      });
    });
  };
  var initAjaxLinks = function initAjaxLinks() {
    $('a.ajax').on("click", function (e) {
      e.preventDefault();
      if ($(this).hasClass('here')) {
        return;
      }
      var href = $(this).attr('href');
      var bits = href.split('/');
      var page = bits[bits.length - 2];
      var state = {
        state: bits.slice(3, -1).join('/'),
        href: href
      };
      if (window.history.state === null || window.history.state.state != state) {
        window.history.pushState(state, document.title, href);
      }
      swapContent(href);
    });
  };
  var swapContent = function swapContent(href) {
    if (swappingContent) {
      return;
    }
    imageLoader.abort('thumbs');
    imageLoader.abort('portfolio');
    swappingContent = true;
    $('body').addClass('swappingContent');
    $.ajax({
      data: {
        action: 'swap_content',
        foo: href,
        is_Safari: phpvars.is_Safari
      },
      success: function success(data) {
        swappingContent = false;
        document.title = data.title;
        window.scrollTo(0, 0);
        $('#main').html(data.content);
        $('#thumbs, #header').css({
          display: 'block'
        });
        $('#thumbs section').css({
          display: 'flex'
        });
        $('body').attr('class', data.bodyClass);
        $('a').removeClass('here');
        $('a[href$="' + href + '"]').addClass('here');
        initAjaxLinks();
        initSlideList();
        animatedThumbList = [];
        imageLoader.refresh('thumbs');
        $(window).trigger('resize');
        imageLoader.refresh('portfolio');
        prepareThumbLinks();
        //add underline to Works link OR reset Works submenu if viewing other pages
        if (data.bodyClass == 'home') {
          $('#works a').addClass('here');
        } else {
          $("#works_menu input").prop("checked", false);
          $("#date").prop("checked", true);
          sort = available = sortlink = availablelink = '';
        }
      }
    });
  };
  var prepareThumbLinks = function prepareThumbLinks() {
    $('.thumb').on("click", function () {
      imageLoader.abort('thumbs');
    });
    $('.thumb, .prevnext').on("click", function (event) {
      event.preventDefault();
      if (isFading) {
        return;
      }
      var img = $(this).attr('href').split('/').reverse()[1];
      var large = $('div.slide[id=' + img + ']');
      History.pushState({
        state: img,
        href: phpvars.siteurl + sortlink + availablelink + '/' + img + '/'
      }, title + ': ' + large.attr('data-title'), sortlink + availablelink + '/' + img + '/');
    });
    $('#close_slides').on("click", function (event) {
      event.preventDefault();
      History.pushState({
        state: sortlink + availablelink + '/thumbs/'
      }, title, $(this).attr('href'));
      $('body').removeClass('slidesOpen');
      $('#header').show();
      $('body').off('touchmove');
    });
  };
  var scaleSlideshow = function scaleSlideshow(theSlide) {
    var img = theSlide.children('img');
    var aspectratio_img = img.attr('data-aspectratio');
    var percent_complete = theSlide.data('percent_complete');
    var w = Math.max(aspectratio_img / aspectratio_window * 100, 100) * (1 + percent_complete * 0.25);
    var h = Math.max(aspectratio_window / aspectratio_img * 100, 100) * (1 + percent_complete * 0.25);
    if (w > h) {
      img.css({
        width: '100%',
        height: 'auto'
      });
    } else {
      img.css({
        width: 'auto',
        height: '100%'
      });
    }
    theSlide.css({
      width: w + 'vw',
      height: h + 'vh',
      top: (100 - h) / 2 + 'vh',
      left: (100 - w) / 2 + 'vw'
    });
  };
  var animateHomeSlide = function animateHomeSlide(theSlide) {
    theSlide.show().css('opacity', 1);
    $({
      foo: 0
    }).animate({
      foo: 100
    }, {
      duration: 5000,
      step: function step(val) {
        if (stopHomeSlides) {
          $(this).finish();
          $('.slide_home').data('percent_complete', 0).hide();
          $('li#contact, li#prints').css({
            'display': 'block'
          });
        }
        // val takes values from 0 to 100 here
        theSlide.data('percent_complete', val / 100);
        scaleSlideshow(theSlide);
        if (val > 70) {
          //fade it
          var op = (val - 70) / 30;
          theSlide.css({
            opacity: 1 - op
          });
          if ($('.slide_home').index(theSlide) == 0) {
            $('#logo').fadeOut(1500);
            $('#thumbs, #header').css({
              display: 'block'
            });
            $('#thumbs section').css({
              display: 'flex'
            });
            $('li#contact, li#prints').css({
              'display': 'block'
            });
          }
          if (val == 100) {
            theSlide.hide();
            if ($('.slide_home').index(theSlide) == 0) {
              History.pushState({
                state: 'thumbs'
              }, title, "thumbs/");
            }
          }

          //trigger next animation
          if (val < 71 && $('.slide_home').index(theSlide) > 0) {
            var nextSlide = $('.slide_home:eq(' + ($('.slide_home').index(theSlide) - 1) + ')');
            if (nextSlide.is(':hidden')) {
              //using visibility as a flag here
              animateHomeSlide(nextSlide);
            }
          }
        }
      },
      easing: "linear"
    });
  };
  var route = function route(mode, sort, available) {
    //coming to home with location set
    if (!sort && !available && phpvars.locations.includes(mode)) {
      sort = mode;
      mode = '';
    }
    if (mode == '' && phpvars.display_slideshow != 'true') {
      mode = 'thumbs';
      History.pushState({
        state: 'thumbs',
        href: phpvars.siteurl + '/thumbs/'
      }, title, "thumbs/");
    }
    $c('ROUTING ' + mode);
    switch (mode) {
      case '':
        //home
        $('body').addClass('loading');
        $('#thumbs, #header').hide();
        stopHomeSlides = false;
        imageLoader.seek('slideshow');
        break;
      case 'thumbs':
        $(document).off('keyup');
        $('body').removeClass('loading');
        $('#slide_home_holder, #logo').hide();
        $('#thumbs, #header').css({
          display: 'block'
        });
        $('#thumbs section').css({
          display: 'flex'
        });
        $('li#contact, li#prints').css({
          'display': 'block'
        });
        $('#slides').stop().fadeOut(300, function () {
          $('.slide').hide();
        });
        if (!$('body').hasClass('is_404')) {
          $('#works a').addClass('here');
        }
        $c('loading from thumbs router');
        imageLoader.seek('thumbs');
        $(window).trigger('resize');
        var url = new URL(window.location.href);
        if (url.hash) {
          var $anchor = $('a[name=' + url.hash.substring(1) + ']');
          if ($anchor.length) {
            var anchorTop = $anchor.offset().top;
            $('html, body').scrollTop(anchorTop);
          }
        }
        if (animatedThumbList.length) {
          for (var _i2 = 0, _animatedThumbList2 = animatedThumbList; _i2 < _animatedThumbList2.length; _i2++) {
            var thumb = _animatedThumbList2[_i2];
            thumb.restart();
          }
        }
        break;
      case 'print':
        break;

      //huh. This is for initial page load. It doesn't get triggered when swapping content.
      case 'prints':
      case 'about':
        $('body').removeClass('loading');
        $('#slide_home_holder, #logo').hide();
        $('li#contact, li#works').css({
          'display': 'block'
        });
        $('#thumbs, #header').css({
          display: 'block'
        });
        if (animatedThumbList.length) {
          for (var _i3 = 0, _animatedThumbList3 = animatedThumbList; _i3 < _animatedThumbList3.length; _i3++) {
            var _thumb = _animatedThumbList3[_i3];
            _thumb.stopAnimation();
          }
        }
        break;
      default:
        //if the query string doesn't match any of the pictures, go to home
        //FIXME: should go to 404?
        if (animatedThumbList.length) {
          for (var _i4 = 0, _animatedThumbList4 = animatedThumbList; _i4 < _animatedThumbList4.length; _i4++) {
            var _thumb2 = _animatedThumbList4[_i4];
            _thumb2.stopAnimation();
          }
        }
        if (!phpvars.slides.includes(mode)) {
          window.location.replace(phpvars.siteurl + '/thumbs/');
        }
        if (!slideList.length) {
          initSlideList();
          imageLoader.seek('portfolio');
        }
        var $slide = $('.slide#' + mode);
        if ($slide.length) {
          var i = slideList.findIndex(function (x) {
            return x.id === mode;
          });
          //needs animation because not loaded via imgloader
          if ($slide.find('figure.extra').length && slideList[i].animation === null) {
            slideList[i].animation = new gnn.animate($slide.find('figure'));
          }
          if (slideList[i].animation != null) {
            slideList[i].animation.start();
          }
          var prev, next;
          if (i == 0) {
            prev = slideList.length - 1;
          } else {
            prev = i - 1;
          }
          if (i == slideList.length - 1) {
            next = 0;
          } else {
            next = i + 1;
          }
          $('#previous_slide').attr({
            href: sortlink + availablelink + '/' + slideList[prev].id + '/'
          });
          $('#next_slide').attr({
            href: sortlink + availablelink + '/' + slideList[next].id + '/'
          });
          imageLoader.seek('portfolio', i);
          var currentSlide = $('.slide:visible');
          if (currentSlide.length) {
            currentSlide.css('z-index', 2);
            var nextSlide = $('.slide#' + mode);
            nextSlide.css({
              'z-index': 1,
              opacity: ''
            });
            nextSlide.show();
            isFading = true;
            currentSlide.fadeOut(600, function () {
              $(this).hide().css('z-index', 'auto');
              isFading = false;
              var id = currentSlide.attr('id');
              var i = slideList.findIndex(function (x) {
                return x.id === id;
              });
              if (slideList[i].animation) {
                slideList[i].animation.stopAnimation();
              }
            });
          } else {
            $('#content').show();
            $('.slide#' + mode).show();
            $('#slides').stop().fadeIn(300, function () {
              //disable scrolling
              $('body').addClass('slidesOpen');
              $('#header').hide();
              $('body').on('touchmove', function (e) {
                e.preventDefault();
              });
            });
          }
          $(document).on("keyup", function (e) {
            var key = e.keyCode;
            switch (key) {
              case 27:
                //esc
                $('#close_slides').trigger('click');
                break;
              case 37:
                $('#previous_slide').trigger('click');
                break;
              case 39:
                $('#next_slide').trigger('click');
                break;
            }
          });
          $("#slides").swipe({
            swipe: function swipe(event, direction, distance, duration, fingerCount, fingerData) {
              switch (direction) {
                case 'right':
                  $('#previous_slide').trigger('click');
                  break;
                case 'left':
                  $('#next_slide').trigger('click');
                  break;
                case 'down':
                  $('#close_slides').trigger('click');
                  break;
              }
            }
          });
        } else {
          History.pushState({
            state: '',
            href: phpvars.siteurl
          }, title, "/");
        }
        break;
    }
  };
  $('.accordion_trigger').on("click", function (e) {
    var $this = $(e.currentTarget);
    var accordion_id = $this.attr('data-accordion');
    var $accordion = $('#' + accordion_id);
    if ($this.hasClass('unique')) {
      $(".accordion_trigger").not($this).each(function (i, el) {
        var $accordion = $('#' + $(el).attr('data-accordion'));
        $(el).removeClass('open');
        $accordion.removeClass('open');
        $accordion.css('max-height', 0);
      });
    }
    if ($this.hasClass('open')) {
      $('[data-accordion=' + accordion_id + ']').removeClass('open');
      $accordion.removeClass('open');
      $accordion.css('max-height', 0);
    } else {
      $('[data-accordion=' + accordion_id + ']').addClass('open');
      $accordion.addClass('open');
      $accordion.css('max-height', $accordion.attr('data-maxH') + 'px');
    }
  });

  //menu
  $('#hamburger').on("click", function (e) {
    if ($('#header').hasClass('open')) {
      $('#header').removeClass('open');
    } else {
      $('#header').addClass('open');
    }
  });
  History.Adapter.on(window, 'statechange', function () {
    /*
    examples:
    http://art.grundig (intro)
    http://art.grundig/thumbs/ (default (by date))
    http://art.grundig/prints/ (page)
    http://art.grundig/price/thumbs/ (sorted)
    http://art.grundig/price/available/thumbs/ (sorted, filtered)
    
    ZOOM:		
    http://art.grundig/saigon-grinder/ (default)
    http://art.grundig/price/saigon-grinder/ (sorted)
    http://art.grundig/price/available/saigon-grinder/ (sorted, filtered)
    */
    //https://github.com/browserstate/history.js/
    var State = History.getState();
    if (State.url == phpvars.siteurl + '/') {
      route('');
      return;
    } else {
      var hashArr = State.hash.split('/').slice(1, -1).reverse();
      mode = hashArr[0];
      if (hashArr.length == 2 && hashArr[1] != 'available') {
        sort = hashArr[1];
        available = '';
      } else {
        sort = hashArr[2];
        available = hashArr[1];
      }
      if (sort) {
        sortlink = '/' + sort;
      } else {
        sortlink = '';
      }
      if (available) {
        availablelink = '/' + available;
      } else {
        availablelink = '';
      }
      if (mode == '/prints/' || mode == '/about/') {
        return;
      } else {
        route(mode, sort, available);
      }
    }
  });
  if (window.location.pathname == '/test/') {
    route('');
  } else {
    $(window).trigger('statechange');
  }
  window.onpopstate = function (e) {
    e.preventDefault();
    if (e.state && e.state.href) {
      swapContent(e.state.href);
    }
  };
  $('#slide_home_holder, #logo').on("click", function () {
    stopHomeSlides = true;
    History.pushState({
      state: 'thumbs',
      href: phpvars.siteurl + '/thumbs/'
    }, title, "thumbs/");
  });
  $('form#gallery_sort input[type=radio], form#gallery_sort input[type=checkbox]').on("click", function (e) {
    if (swappingContent) {
      e.preventDefault();
    }
  });
  $('form#gallery_sort input[type=radio]').on("change", function (e) {
    var url, href, state;
    var $this = $(e.currentTarget);
    sort = $this.attr('id');
    if (sort == 'date') {
      sortlink = '';
      url = availablelink + '/thumbs/';
    } else {
      var loc = $this.attr('data-location');
      var cat = $this.attr('data-category');
      if (loc) {
        sortlink = '/' + loc;
      } else if (cat) {
        sortlink = '/' + cat;
      } else {
        sortlink = '/' + sort;
      }
      url = sortlink + availablelink + '/thumbs/';
    }
    var print = phpvars.siteurl + url.replace('/thumbs/', '/print/');
    $('#pdf a').attr('href', print);
    href = phpvars.siteurl + url;
    state = {
      state: url,
      href: href
    };
    if (window.history.state === null || window.history.state.state != state) {
      window.history.pushState(state, document.title, href);
    }
    $('body').addClass('swappingContent');
    $('#hamburger').trigger('click');
    $('#header').removeClass('open');
    swapContent(href);
  });
  $('form#gallery_sort input[type=checkbox]').on("change", function (e) {
    var url, href, state;
    var $this = $(e.currentTarget);
    if ($this.is(":checked")) {
      availablelink = '/available';
    } else {
      availablelink = '';
    }
    if (sort == 'date' || sort == undefined) {
      url = availablelink + '/thumbs/';
    } else {
      url = sortlink + availablelink + '/thumbs/';
    }
    var print = phpvars.siteurl + url.replace('/thumbs/', '/print/');
    $c(url);
    $c('print ' + print);
    $('#pdf a').attr('href', print);
    href = phpvars.siteurl + url;
    state = {
      state: url,
      href: href
    };
    if (window.history.state === null || window.history.state.state != state) {
      window.history.pushState(state, document.title, href);
    }
    $('body').addClass('swappingContent');
    $('#hamburger').trigger('click');
    $('#header').removeClass('open');
    swapContent(href);
  });

  //init imageLoader
  initSlideList();
  initAjaxLinks();
  prepareThumbLinks();
  initAccordion($('.accordion'));
});
var $c = function $c(t) {
  if (phpvars.siteurl != 'http://art.grundig') {
    return;
  }
  console.log(t);
};